// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}orgStruct/getUserApproveOrgPageList`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}orgStruct/list/export/pending`
// 导出列表
const exportRejectedURL = `${API_CONFIG.butlerBaseURL}orgStruct/list/export/rejected`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 操作
const batchURL = `${API_CONFIG.butlerBaseURL}orgStruct/batchCommitStructUserApprove`
// 获取详情
const getDetailURL = `${API_CONFIG.butlerBaseURL}orgStruct/selectUserApproveById`

export {
  getListURL,
  exportListURL,
  exportRejectedURL,
  getOrgListURL,
  batchURL,
  getDetailURL
}
