<!--
 * @Description: 用户审核--关联组织审核-未通过列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-26 10:15:59
 -->
<template>
  <div class="relevanceOrgAuditList-nopass-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-datepicker
          label="申请时间段"
          type="rangedatetimer"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        />
        <v-input label="姓名" v-model="searchParams.userName" :maxlength="10"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum" :maxlength="11"></v-input>
        <v-select label="性别" v-model="searchParams.sex" :options="sexOps"></v-select>
        <v-select2
          label="所属组织"
          v-model="searchParams.orgId"
          v-bind="orgParams"
        ></v-select2>
        <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>
        <v-select2
          label="所属公司"
          v-model="searchParams.regionId"
          v-bind="tenantParams"
        ></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="查看原因"
          type="text"
          permission="msgAlert"
          @click="look(scope.row)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportRejectedURL, getOrgListURL } from './api'
import { sexMap, sexOps } from './map'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: '',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportRejectedURL,
      sexOps: sexOps,
      communityParams,
      tenantParams,
      orgParams: {
        searchUrl: getOrgListURL,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName'
        }
      },
      searchParams: {
        approveStatus: 2,
        userName: '',
        mobileNum: '',
        orgId: '',
        startTime: '',
        endTime: '',
        communityId: '',
        regionId: '',
        sex: undefined
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'sexText',
          label: '性别',
          formatter (row) {
            return sexMap[row.sex]
          }
        },
        {
          prop: 'communityAddress',
          label: '项目住址'
        },
        {
          prop: 'orgName',
          label: '所属组织'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'companyName',
          label: '所属公司'
        },
        {
          prop: 'intime',
          label: '申请时间'
        }
      ]
    }
  },
  methods: {
    look (row) {
      // 查看原因
      this.$alert(row.approveComments, {
        title: '拒绝原因',
        closeOnClickModal: true,
        showConfirmButton: false,
        showClose: true
      })
    }
  }
}
</script>

<style scope lang="scss">
.relevanceOrgAuditList-nopass-wrapper {
    height: 100%;
}
</style>
