
import { generateMapByOpts } from 'common/utils'

// 性别
const sexOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]

const statusMap = {
  0: '未审核',
  1: '通过',
  2: '拒绝'
}

const statusOps = [
  {
    text: '审核通过',
    value: 1
  },
  {
    text: '拒绝',
    value: 2
  }
]

const sexMap = generateMapByOpts(sexOps)

export {
  sexOps,
  sexMap,
  statusOps,
  statusMap
}
